/* eslint-disable no-underscore-dangle */
import { isAnyFunction } from '~/helpers/function';

// Should match .env.example
const expected = {
    BASE_URL: String,
    DEV: Boolean,
    MODE: String,
    PROD: Boolean,
    SSR: Boolean,
    VITE_API_URL: String,
    VITE_GRAPHQL_URL: String,
    VITE_APP_URL: String,
    VITE_PUSHER_HOST: String,
    VITE_PUSHER_KEY: String,
    VITE_PUSHER_CLUSTER: String,
    VITE_SERVER_ENVIRONMENT: ['local', 'test', 'testing', 'release', 'production'],
    VITE_RECAPTCHA_KEY: String,
    VITE_GOOGLE_MAPS_API_KEY: String,
    CYPRESS_BASE_URL: String,
    VITE_EXPENSES_ENABLED: String,
    VITE_MILEAGE_ENABLED: String,
    VITE_CHECKLISTS_ENABLED: String,
} as const;

const ignore = [
    'VITE_APP_DEV_SERVER_PORT',
    'VITE_APP_DEV_WS_HMR',
];

type ExpectedEnv = typeof expected;

type EnvVar = keyof ExpectedEnv;

type _EnvValue<K extends EnvVar> = ExpectedEnv[K];

type EnvValue<K extends EnvVar> = _EnvValue<K> extends (...args: any[]) => any
    ? ReturnType<_EnvValue<K>>
    : _EnvValue<K> extends readonly any[]
        ? _EnvValue<K>[number]
        : 'UNHANDLED ENV VARIABLE TYPE'

export type Env = {
    [K in EnvVar]: EnvValue<K>
}

const actual = import.meta.env;
const _isDev = import.meta.env.DEV;
const _isTest = import.meta.env.VITEST;

const _env = {} as Env;

for (const [key, value] of Object.entries(actual)) {
    if (ignore.includes(key)) {
        continue;
    }

    let envValue: any;

    if (key in expected) {
        const expectedValue = expected[key as keyof Env];

        if (isAnyFunction(expectedValue)) {
            envValue = expectedValue(value);
        } else if (Array.isArray(expectedValue) && expectedValue.includes(value)) {
            envValue = value;
        } else {
            if (_isDev) {
                console.error(`Unexpected env var type for ${key}:`, value, '\n', 'Expected:', expectedValue);
            }

            envValue = value;
        }
    } else {
        if (_isDev && !_isTest) {
            console.warn(`Unexpected env var: ${key}`);
        }

        envValue = value;
    }

    Reflect.set(_env, key, envValue);
}

export default function env<K extends EnvVar>(key: K, fallback?: Env[K]): Env[K] {
    const value = _env[key] || fallback;

    if (_isDev && (value === '' || value === undefined || value === null)) {
        console.error(`Env var ${key} is not defined`);
    }

    return value as Env[K];
}

export function featureFlag<K extends EnvVar>(key: K, fallback?: boolean): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return env(key, fallback ? 'true' : 'false') === 'true';
}

export function isDev(): boolean {
    return env('DEV', false);
}

export function isLocal(): boolean {
    return env('VITE_SERVER_ENVIRONMENT', 'local') === 'local';
}

export function isTesting(): boolean {
    return env('VITE_SERVER_ENVIRONMENT', 'testing') === 'testing' || _isTest;
}
