export default {
    Legend: 'Legenda',
    Draft: 'Concept',
    Final: 'Definitief',
    'Plannable wishes': 'Planbare wensen',
    'No plannable wishes available': 'Geen planbare wensen beschikbaar',
    'Preference date': 'Voorkeursdatum',
    'Final date': 'Definitieve datum',
    Filters: 'Filters',
    'Show preference date': 'Voorkeursdatum tonen',
    Preference: 'Voorkeur',
    'Start date': 'Startdatum',
    days: 'dagen',
    'Showing {start} to {end} of {total} results': 'Toont {start} tot {end} van {total} resultaten',
    'No results': 'Geen resultaten',
    'Departure at {time} hours': 'Vertrek om {time} uur',
    'On date': 'Op',
    'Vehicle planning': 'Voertuigplanning',
    'Wish planning': 'Wensplanning',
    'Assignable wishes': 'Toe te wijzen wensen',
    'There are no more wishes to assign to a vehicle!': 'Er zijn geen wensen meer om aan een voertuig toe te wijzen!',
    'Save dayplanning': 'Dagplanning opslaan',
    'The wish planning for this day still has wishes in draft mode!': 'De wensplanning voor deze dag heeft nog wensen die op concept staan!',
    'Search wish': 'Zoek naar wens',
    'Dayplanning confirmed': 'Dagplanning bevestigd',
    'Dayplanning confirmation failed': 'Dagplanning bevestigen mislukt',
    'The day planning for this date has been confirmed.': 'De dagplanning voor deze datum is bevestigd.',
    'The day planning for this date could not be confirmed.': 'De dagplanning voor deze datum kon niet worden bevestigd.',
    'The departure at time from the Ambulance Wish office must be provided before the wish planning may be finalized.': 'Vertrek tijdstip vanaf Ambulance Wens moet ingevuld zijn voordat de planning voor deze wens definitief gemaakt kan worden.',
    'Go to wish detail page': 'Ga naar wens detail pagina',
    'Wish in the past': 'Wens in het verleden',
    'Vehicleplanning confirmed': 'Voertuigplanning definitief',
    'Volunteers confirmed': 'Vrijwilligers bevestigd',
    'Volunteer(s) not confirmed': 'Vrijwilliger(s) niet bevestigd',
    'Vehicle unavailable': 'Voertuig niet beschikbaar',
    'Finalize wish planning': 'Wensplanning afronden',
    'Please note, the wish planning has not been saved yet!': 'Let op, de wensplanning is nog niet opgeslagen!',
    'Do you wish to edit the standard mail that is being sent to the contactperson for this wish?': 'Wilt u de standaardmail die wordt verstuurd naar de contactpersoon voor deze wens aanpassen?',
    'Edit the extra information that will be included in the mail': 'Pas de extra informatie aan die met de mail wordt meegezonden',
    'Reservation': 'Reservering',
    'Multiple wishes on 1 day': 'Meerdere wensen op 1 dag',
    'The selected volunteer is already scheduled to fulfill a wish. Are you sure you want the volunteer to fulfill another wish?': 'De geselecteerde vrijwilliger staat al ingepland voor het vervullen van een wens. Weet je zeker dat je de vrijwilliger nog een wens wilt laten uitvoeren?',
    'Attach vehicle': 'Voertuig koppelen',
};
