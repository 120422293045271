export default {
    'Other information': 'Overige informatie',
    'Relationship to patient': 'Relatie tot de patiënt',
    'Health insurer name': 'Zorgverzekeraar',
    'Health insurer policy number': 'Zorgverzekeringsnummer',
    'Covid positive': 'Is de patiënt Covid positief?',
    'Medical supplies': 'Medische hulpmiddelen',
    'Resuscicate': 'Wilt de patiënt gereanimeerd worden?',
    'Resuscicate statement': 'Heeft de patiënt een DNR (Do Not Resuscitate) verklaring?',
    'Mobility status': 'Hoe mobiel is de patiënt?',
    'Mobility status divergent': 'Toelichting mobiliteit',
    'Notes': 'Opmerkingen',
    'Reply': 'Reageer',
    'Add a note': 'Typ hier je opmerking',
    'Add a reply': 'Reageer op deze opmerking',
    'Add note': 'Opmerking plaatsen',
    'Add reply': 'Reactie plaatsen',
    'File': 'Bestand',
    'Add a description to the file': 'Voeg een omschrijving toe aan het bestand.',
    'Change the wish status from': 'De status van de wens aanpassen van',
    'to': 'naar',
    'Type a reason for the status change': 'Type de reden voor het aanpassen van de status',
    'Update status': 'Status aanpassen',
    'Description': 'Omschrijving',
    'Floor level': 'Verdieping',
    'Ground floor': 'Begane grond',
    'First floor': '1e verdieping',
    'Second floor': '2e verdieping',
    'Third floor': '3e verdieping',
    'Higher floor': 'Hogere verdieping',
    'Is the location accessible for stretchers?': 'Is de locatie brandcard toegankelijk?',
    'Is there an elevator?': 'Is er een lift aanwezig?',
    'Elevator': 'Lift',
    'Stretcher accessible': 'Brancard toegankelijk',
    'Name or description of the location': 'Naam of beschrijving van de locatie',
    'Description of the location where the patient will be picked up': 'Beschrijving van de locatie waar de patiënt opgehaald mag worden',
    'Example: hospital or hospice': 'Bijvoorbeeld: ziekenhuis of hospice',
    'Date and time': 'Datum en tijd',
    'Departure': 'Vertrek',
    'Arrival': 'Aankomst',
    'Departure from Ambulance Wish': 'Vertrek vanaf Ambulance Wens',
    'Arrival at wish location': 'Aankomst op wenslocatie',
    'Arrival at pickup location': 'Aankomst op ophaallocatie',
    'Departure from wish location': 'Vertek vanaf wenslocatie',
    'Add wish location': 'Wenslocatie toevoegen',
    'Google Maps': 'Google Maps',
    "Map": 'Kaart',
    'Patient residence': 'Verblijfplaats patient',
    'Irrelevant': 'N.v.t.',
    'Edit pickup location': 'Ophaallocatie wijzigen',
    'Edit wish location': 'Wenslocatie wijzigen',
    'Edit vehicle': 'Voertuig wijzigen',
    'Add vehicle': 'Voertuig toevoegen',
    'No vehicle added': 'Geen voertuig toegevoegd',
    'There is no vehicle linked. To link a vehicle, go to the': 'Er is geen voertuig gekoppeld. Om een voertuig te koppelen, ga je naar de',
    'Directions': 'Route',
    'Subject': 'Onderwerp',
    'Add ContactPerson': 'Contactpersoon toevoegen',
    'Extra ContactPerson': 'Extra contactpersoon',
    'Receives mail': 'Updates van wens sturen',
    'Actions': 'Acties',
    'Open actions': 'Openstaande acties',
    'Complete all actions to complete the wish': 'Voltooi alle acties om de wens te kunnen afronden',
    'Open': 'Open',
    'Done': 'Afgerond',
    'Checklist driver': 'Controlelijst chauffeur',
    'Checklist medical': 'Controlelijst medisch',
    'Medication check-off list': 'Medicatie aftekenlijst',
    'Publication permission': 'Toestemming publicatie',
}
